<template>
    <div class="card">
        <div class="flex justify-center mb-4">
            <el-upload
                ref="upload"
                action="#"
                :class="{ 'hide-new-file': employeeData.file }"
                list-type="picture-card"
                :on-change="handleChange"
                :multiple="false"
                accept=".jpg,.png,.jpeg"
                :limit="1"
                :auto-upload="false"
            >
                <i slot="default" class="el-icon-camera" />
                <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                        <span
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                        >
                            <i class="el-icon-delete" />
                        </span>
                    </span>
                </div>
            </el-upload>
        </div>

        <el-form ref="form" :model="employeeData" :rules="employeeDataRules" label-width="250px" class="employee-add-form">
            <el-row :gutter="15">
                <el-col :span="16" :offset="1">
                    <el-form-item :label="$t('employees.name')" prop="name">
                        <el-input v-model="employeeData.name" class="pl-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.surname')" prop="surname">
                        <el-input v-model="employeeData.surname" class="pl-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.email_login_as_well')" prop="email">
                        <el-input v-model="employeeData.email" autocomplete="new-password" class="pl-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.password')" prop="password">
                        <el-input v-model="employeeData.password" type="password" :show-password="true" autocomplete="new-password" class="pl-3" />
                    </el-form-item>
                    <el-form-item label="Personnummer">
                        <el-input v-model="employeeData.personnummer" type="number" class="pl-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.telephone_number')">
                        <el-input v-model="employeeData.telephone_number" class="pl-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.first_day_at_work')">
                        <el-date-picker
                            v-model="employeeData.started_working_at"
                            :editable="false"
                            :clearable="false"
                            format="yyyy.MM.dd"
                            :picker-options="{ firstDayOfWeek: 1 }"
                            type="date"
                            class="ml-3 pr-3"
                        >
                            <!-- format="dd.MM.yyyy" -->
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$t('employees.account_number')">
                        <el-input v-model="employeeData.account_number" class="pl-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.clearing_number')">
                        <el-input v-model="employeeData.clearing_number" class="pl-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.contract_type')" prop="contract_type">
                        <el-select v-model="employeeData.contract_type" class="pl-3">
                            <el-option value="" :label="$t('employees.none')" />
                            <el-option value="Provanställning" label="Provanställning" />
                            <el-option value="Tillsvidareanställning" label="Tillsvidareanställning" />
                            <el-option value="Vikarieanställning" label="Vikarieanställning" />
                            <el-option value="Visstidsanställning" label="Visstidsanställning" />
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('employees.bid_per_hour')" class="pr-3">
                        <money v-model="employeeData.bid_per_hour" class="el-input__inner ml-3 money_input el-input--small" v-bind="money" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.working_hours_week')" prop="working_hours_week" class="pr-3">
                        <el-input-number v-model="employeeData.working_hours_week" :min="0" :max="80" class="ml-3" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.assign_to_manager')">
                        <el-select v-model="employeeData.manager_id" clearable class="pl-3">
                            <el-option
                                v-for="manager in managers"
                                :key="manager.id"
                                :value="manager.id"
                                :label="manager.name + ' ' + manager.surname"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import { Money } from 'v-money';
import VueTheMask from 'vue-the-mask';

Vue.use(Money);
Vue.use(VueTheMask);

export default {
    components: { Money },

    data() {
        return {
            employeeData: {
                file:               null,
                name:               null,
                surname:            null,
                email:              null,
                password:           null,
                contract_type:      'Provanställning',
                telephone_number:   null,
                personnummer:       null,
                started_working_at: this.$dayjs(),
                account_number:     null,
                clearing_number:    null,
                bid_per_hour:       0,
                working_hours_week: 40,
                manager_id:         null,
            },
            managers: [],
            money:    {
                decimal:   ',',
                thousands: '',
                precision: 2,
                masked:    true,
            },
            employeeDataRules: {
                name:               [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                surname:            [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                working_hours_week: [{ type: 'integer', message: this.$t('common.wrong_format'), trigger: 'blur' }],
                email:              [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' },
                ],
                password: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { min: 5, message: this.$t('common.min_5_characters'), trigger: 'blur' },
                ],
            },
        };
    },

    watch: {
        employeeData: {
            handler() {
                this.$emit('change', this.employeeData);
            },
            deep: true,
        },
    },

    created() {
        this.getManagers();
    },

    methods: {
        async getManagers() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers`);
            this.managers = data;
        },

        handleRemove() {
            this.$refs.upload.clearFiles();
            this.employeeData.file = null;
        },

        handleChange(file) {
            this.employeeData.file = file;
        },
    },
};
</script>
<style scoped>
    .el-select {
        width: 100%;
    }
</style>
<style>
.el-form-item__error {
    padding-left: 12px;
}

.employee-add-form .el-select,
.employee-add-form .el-input-number--small {
    width: 100%;
}

.employee-add-form .el-date-editor.el-input, .employee-add-form .el-date-editor.el-input__inner {
    width: 100%;
}
.employee-add-form .money_input {
    padding-right: 50px !important;
    height: 32px !important;
}
.hide-new-file .el-upload {
    display: none !important;
}
</style>
