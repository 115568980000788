<template>
    <div class="pb-3 px-48">
        <el-steps class="card" :active="activeStep" finish-status="success" align-center>
            <el-step :title="$t('employees.employee_information')" />
            <el-step :title="$t('employees.address')" />
            <el-step :title="$t('employees.extra')" />
        </el-steps>

        <StepOne v-show="activeStep === 1" ref="step_1" @change="employeeData = $event" />

        <StepTwo v-show="activeStep === 2" ref="step_2" @change="addressData = $event" />

        <StepThree v-show="activeStep === 3" ref="step_3" @change="noteData = $event" />

        <el-button type="primary" :disabled="activeStep <= 1" @click="previousStep">
            {{ $t('employees.previous') }}
        </el-button>
        <el-button type="primary" :disabled="activeStep === 3" @click="nextStep">
            {{ $t('employees.next') }}
        </el-button>
        <el-button v-if="activeStep === 3" type="success" :loading="$wait.is('creating')" @click="nextStep">
            {{ $t('employees.finish') }}
        </el-button>
    </div>
</template>
<script>
import StepOne from './components/EmployeesCreateStepOne.vue';
import StepTwo from './components/EmployeesCreateStepTwo.vue';
import StepThree from './components/EmployeesCreateStepThree.vue';

export default {
    components: { StepOne, StepTwo, StepThree },

    data() {
        return {
            activeStep:   1,
            employeeData: {},
            addressData:  {},
            noteData:     '',
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('employees.add_new_employee'));
        this.$store.commit('setActiveMenuItem', '3-2');
    },

    methods: {
        async createEmployeeRequest() {
            this.$wait.start('creating');

            const { data } = await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`, {
                // Employee data
                employee_name:               this.employeeData.name,
                employee_surname:            this.employeeData.surname,
                employee_email:              this.employeeData.email,
                employee_password:           this.employeeData.password,
                employee_telephone_number:   this.employeeData.telephone_number,
                employee_personnummer:       this.employeeData.personnummer,
                employee_started_working_at: this.$dayjs(this.employeeData.started_working_at).unix(),
                employee_account_number:     this.employeeData.account_number,
                employee_clearing_number:    this.employeeData.clearing_number,
                employee_contract_type:      this.employeeData.contract_type,
                employee_bid_per_hour:       this.employeeData.bid_per_hour.replace(',', ''),
                employee_working_hours_week: this.employeeData.working_hours_week,
                employee_manager_id:         this.employeeData.manager_id,

                // Address data
                address_co:        this.addressData.co,
                address_street:    this.addressData.street,
                address_post_code: this.addressData.post_code,
                address_city:      this.addressData.city,
                address_map_lat:   this.addressData.map_lat,
                address_map_lng:   this.addressData.map_lng,

                // Note data
                note: this.noteData,
            });

            await this.uploadAvatar(data);

            this.$notify.success({ title: 'Success' });
            this.$wait.end('creating');
            this.$router.push({ name: 'employees_all' });
        },

        async uploadAvatar(employeeUuid) {
            if (!this.employeeData.file) return;
            try {
                const formData = new FormData();
                formData.append('file', this.employeeData.file.raw);

                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${employeeUuid}/upload_avatar`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
            } catch {
                console.log('Error while uploading image');
            }
        },

        async nextStep() {
            if (this.activeStep < 3) {
                await new Promise(resolve => this.$refs[`step_${this.activeStep}`].$refs.form.validate(valid => valid && resolve()));
                this.activeStep++;
                return;
            }

            this.createEmployeeRequest();
        },

        previousStep() {
            this.activeStep--;
        },
    },
};
</script>
