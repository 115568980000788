<template>
    <div class="card employee-add">
        <quill-editor
            ref="myQuillEditor"
            v-model="noteData"
            :options="editorOption"
            @change="noteDataChanged"
        />
    </div>
</template>
<script>
// @Filip
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
    components: {
        quillEditor,
    },

    data() {
        return {
            noteData:     '',
            editorOption: {
                placeholder: ' ',
                debug:       false,
                modules:     {
                    toolbar: [
                        [{ header: 1 }, { header: 2 }],
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ align: [] }],
                    ],
                },
            },
        };
    },

    methods: {
        noteDataChanged() {
            this.$emit('change', this.noteData);
        },
    },
};
</script>
<style>
    .employee-add .ql-editor {
        height: 400px;
    }
</style>
